import { Injectable } from '@angular/core';
import {
  ArriveFreightStopCommand,
  ArriveFreightStopCommandHandler,
  DepartFreightStopCommand,
  DepartFreightStopCommandHandler,
  ReplaceScheduledJourneyDriverCommand,
  ReplaceScheduledJourneyDriverCommandHandler,
  UpdateMailingPreferencesCommand,
  UpdateMailingPreferencesCommandHandler,
  UploadFreightDocumentCommand,
  UploadFreightDocumentCommandHandler,
  VerifyFreightStopGoodsInfoCommand,
  VerifyFreightStopGoodsInfoCommandHandler,
} from '@okcargo/command-processor';
import { BaseCommandRepository } from 'src/app/common/infrastructure/command.repository';

@Injectable({ providedIn: 'root' })
export class FreightCommandService {
  private readonly arriveFreightStopCommandHandler: ArriveFreightStopCommandHandler;
  private readonly departFreightStopCommandHandler: DepartFreightStopCommandHandler;
  private readonly verifyFreightStopGoodsInfoCommandHandler: VerifyFreightStopGoodsInfoCommandHandler;
  private readonly uploadFreightDocumentCommandHandler: UploadFreightDocumentCommandHandler;
  private readonly replaceScheduledJourneyDriverCommandHandler: ReplaceScheduledJourneyDriverCommandHandler;

  // account
  private readonly updateMailingPreferencesCommandHandler: UpdateMailingPreferencesCommandHandler;

  constructor(commandRepository: BaseCommandRepository) {
    this.arriveFreightStopCommandHandler = new ArriveFreightStopCommandHandler(commandRepository);
    this.departFreightStopCommandHandler = new DepartFreightStopCommandHandler(commandRepository);
    this.verifyFreightStopGoodsInfoCommandHandler = new VerifyFreightStopGoodsInfoCommandHandler(commandRepository);
    this.uploadFreightDocumentCommandHandler = new UploadFreightDocumentCommandHandler(commandRepository);
    this.replaceScheduledJourneyDriverCommandHandler = new ReplaceScheduledJourneyDriverCommandHandler(
      commandRepository,
    );
    // account
    this.updateMailingPreferencesCommandHandler = new UpdateMailingPreferencesCommandHandler(commandRepository);
  }

  public arriveToFreightStop(command: ArriveFreightStopCommand): Promise<void> {
    return this.arriveFreightStopCommandHandler.handle(command);
  }

  public departFromFreightStop(command: DepartFreightStopCommand): Promise<void> {
    return this.departFreightStopCommandHandler.handle(command);
  }

  public verifyFreightStopGoodsInfo(command: VerifyFreightStopGoodsInfoCommand): Promise<void> {
    return this.verifyFreightStopGoodsInfoCommandHandler.handle(command);
  }

  public uploadFreightDocument(command: UploadFreightDocumentCommand): Promise<void> {
    return this.uploadFreightDocumentCommandHandler.handle(command);
  }

  public replaceScheduledJourneyDriver(command: ReplaceScheduledJourneyDriverCommand): Promise<void> {
    return this.replaceScheduledJourneyDriverCommandHandler.handle(command);
  }

  // account
  public updateMailingPreferences(command: UpdateMailingPreferencesCommand): Promise<void> {
    return this.updateMailingPreferencesCommandHandler.handle(command);
  }
}
