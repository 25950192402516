import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdateMailingPreferencesCommand } from '@okcargo/command-processor';
import { enGB, es } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { BehaviorSubject, firstValueFrom, map, Observable, tap } from 'rxjs';
import { JwtService } from 'src/app/auth/infrastructure/jwt.service';
import { ApiService } from 'src/app/common/infrastructure/api.service';
import { FreightCommandService } from 'src/app/freight/infrastructure/freight-command.service';
import { ECapabilities, getEmptyIAccount, IAccount, ICredentials } from '../domain/account.model';
import { transformToIAccount } from '../infrastructure/account-mapper';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly apiService = inject(ApiService);
  private readonly jwtService = inject(JwtService);
  private readonly freightCommandService = inject(FreightCommandService);
  private readonly translate = inject(TranslateService);
  private readonly dateFnsConfigurationService = inject(DateFnsConfigurationService);

  private readonly _accountUrl = '/services/uaa/api/account';
  private readonly _companyUrl = '/services/uaa/api/carriers/mine';
  private readonly _companyCapabilitiesUrl = '/services/uaa/api/company-capabilities/company';
  private readonly _companyCarriersUrl = '/services/uaa/api/company-carriers';
  private readonly _changePasswordUrl = '/services/uaa/api/account/change-password';
  private readonly _changeLaguageUrl = '/services/uaa/api/account/change-language';

  private readonly _account$: BehaviorSubject<IAccount> = new BehaviorSubject(getEmptyIAccount());

  constructor() {}

  get account$(): Observable<IAccount> {
    return this._account$.asObservable();
  }

  public async checkUserAuthentication(): Promise<void> {
    try {
      const user = await this.getIdentity();
      this._account$.next(user);
      this.setDateFnsLocale(user.lang);
      this.jwtService.setUserDataReceived(true);
    } catch (error) {
      console.warn(error);
    }
  }

  public hasCapability$(capability: ECapabilities): Observable<boolean> {
    return this._account$.pipe(map((account) => account.capabilities.includes(capability)));
  }

  public get userCapabilities$(): Observable<ECapabilities[]> {
    return this._account$.pipe(map((account) => account.capabilities || []));
  }

  public async login(credentials: ICredentials): Promise<void> {
    console.log('login() in account service');
    await firstValueFrom(
      this.jwtService.login$(credentials).pipe(
        tap((response) => {
          this.jwtService.setAccessToken(response.access_token);
          this.jwtService.setRefreshToken(response.refresh_token);
        }),
      ),
    );
    await this.checkUserAuthentication();
  }

  public async validateToken(token: string): Promise<void> {
    console.log('validateToken() in account service');
    await firstValueFrom(
      this.jwtService.validateToken$(token).pipe(
        tap((response) => {
          this.jwtService.setAccessToken(response.access_token);
          this.jwtService.setRefreshToken(response.refresh_token);
        }),
      ),
    );
    await this.checkUserAuthentication();
  }

  public async logout(): Promise<void> {
    await firstValueFrom(this.jwtService.logout$());
    this._account$.next(getEmptyIAccount());
    this.jwtService.clearTokens();
  }

  public saveLang(lang: string): Promise<ArrayBuffer> {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.setDateFnsLocale(lang);
    return firstValueFrom(
      this.apiService.post(
        this._changeLaguageUrl,
        {
          langKey: lang,
        },
        { observe: 'response' },
      ),
    );
  }

  public async changePassword(currentPassword: string, newPassword: string): Promise<ArrayBuffer> {
    return await firstValueFrom(
      this.apiService.post(
        this._changePasswordUrl,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        { observe: 'response' },
      ),
    );
  }

  public async updateMailingPreferences(command: UpdateMailingPreferencesCommand): Promise<void> {
    try {
      await this.freightCommandService.updateMailingPreferences(command as UpdateMailingPreferencesCommand);
    } catch (error: any) {
      // TODO implement this
      console.warn(error);
    }
  }

  private async getIdentity(): Promise<IAccount> {
    const account = this._account$.getValue();
    const accountResponse = await this.findAccount();
    const companyResponse = await this.findCompany();
    const companyCapabilitiesResponse = await this.findCompanyCapabilities();
    const companyAsCarrier = await this.findCompanyAsCarrier();

    return transformToIAccount(
      accountResponse,
      companyResponse,
      companyCapabilitiesResponse,
      companyAsCarrier,
      account,
    );
  }

  private async findCompanyCapabilities(): Promise<any> {
    return await firstValueFrom(
      this.apiService.get(this._companyCapabilitiesUrl, {
        observe: 'body',
      }),
    );
  }

  private async findCompany(): Promise<any> {
    return await firstValueFrom(this.apiService.get(this._companyUrl, { observe: 'body' }));
  }

  private async findAccount(): Promise<any> {
    return await firstValueFrom(this.apiService.get(this._accountUrl, { observe: 'body' }));
  }

  private async findCompanyAsCarrier(): Promise<any> {
    return await firstValueFrom(this.apiService.get(this._companyCarriersUrl, { observe: 'body' }));
    // Data transformation in the mapper transformToIAccount(), only get cost centers and assign capabilty to send freight
  }

  private setDateFnsLocale(lang: string): void {
    if (lang === 'es') {
      this.dateFnsConfigurationService.setLocale(es);
    } else {
      this.dateFnsConfigurationService.setLocale(enGB);
    }
  }

  // TODO ¿?¿?¿?
  /*
  acceptPrivacyPolicy() {
    return this.http.put(
      ApiService.API_URL + '/services/uaa/api/account/privacy-policy',
      {},
      { observe: 'response' }
    );
  }

  */
}
