import { inject, Injectable } from '@angular/core';
import { Command, CommandRepository } from '@okcargo/command-processor';
import { firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class BaseCommandRepository implements CommandRepository {
  private readonly apiService = inject(ApiService);

  private readonly _baseUrl = '/services/command-processor/api/commands';

  constructor() {}

  execute(_name: string, command: Command): Promise<any> {
    return firstValueFrom(this.apiService.post(this._baseUrl, { name: _name, payload: command }, { observe: 'body' }));
  }
}
