import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ICredentials } from 'src/app/account/domain/account.model';
import { ApiService } from 'src/app/common/infrastructure/api.service';
import { StorageService } from 'src/app/common/infrastructure/storage.service';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private readonly storageService = inject(StorageService);
  private readonly apiService = inject(ApiService);

  private readonly _refreshTokenUrl = '/auth/refresh';
  private readonly _loginUrl = '/auth/login';
  private readonly _logoutUrl = '/auth/logout';
  private readonly _tokenLoginUrl = '/auth/carrierDoc';

  private readonly accessTokenSubject = new BehaviorSubject<string>('');
  private readonly refreshTokenSubject = new BehaviorSubject<string>('');
  private readonly usernameSubject = new BehaviorSubject<string>('');
  private readonly userDataReceivedSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  public get accessToken(): string {
    return this.accessTokenSubject.getValue();
  }

  public get refreshToken(): string {
    return this.refreshTokenSubject.getValue();
  }

  public get username(): string {
    return this.usernameSubject.getValue();
  }

  public get userDataReceived$(): Observable<boolean> {
    return this.userDataReceivedSubject;
  }

  public setAccessToken(token: string): void {
    this.accessTokenSubject.next(token);
    this.storageService.create('accessToken', token);
  }

  public setRefreshToken(token: string): void {
    this.refreshTokenSubject.next(token);
    this.storageService.create('refreshToken', token);
  }

  public setUsername(username: string): void {
    this.usernameSubject.next(username);
    this.storageService.create('username', username);
  }

  public setUserDataReceived(value: boolean): void {
    this.userDataReceivedSubject.next(value);
  }

  public clearTokens(): void {
    this.setAccessToken('');
    this.setRefreshToken('');
    this.setUserDataReceived(false);
    this.storageService.delete('accessToken');
    this.storageService.delete('refreshToken');
  }

  public requestNewAuthToken$(): Observable<any> {
    const data = {
      refreshToken: this.refreshToken,
    };
    // TODO review this, refactor apiService methods params
    return this.apiService.post(this._refreshTokenUrl, data, {}, false).pipe(
      // TODO review this, type
      tap((response: any) => {
        this.setAccessToken(response.access_token);
        this.setRefreshToken(response.refresh_token);
      }),
      map((response: any) => {
        return response.access_token;
      }),
    );
  }

  public login$(credentials: ICredentials): Observable<any> {
    this.setUsername(credentials.username);
    return this.apiService.post(
      this._loginUrl,
      credentials,
      {
        observe: 'body',
      },
      false,
    );
  }

  public validateToken$(token: string): Observable<any> {
    return this.apiService.post(
      this._tokenLoginUrl,
      {
        uuid: token,
      },
      {
        observe: 'body',
      },
      false,
    );
  }

  public logout$(): Observable<any> {
    return this.apiService.post(this._logoutUrl, null);
  }
}
