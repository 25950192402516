import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  async create(key: string, value: string): Promise<void> {
    await Preferences.set({ key, value });
  }

  async read(key: string): Promise<GetResult> {
    return Preferences.get({ key });
  }

  async update(key: string, value: string): Promise<void> {
    await Preferences.set({ key, value });
  }

  async delete(key: string): Promise<void> {
    await Preferences.remove({ key });
  }

  async clear(): Promise<void> {
    await Preferences.clear();
  }
}
